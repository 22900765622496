var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"width":"380"}},[_c('v-list',{staticClass:"altura",attrs:{"two-line":""}},[_c('v-list-item',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center  justify-sm-start altura",attrs:{"cols":"12","sm":"2"}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"0px"},on:{"mouseover":function($event){_vm.edad();
                      toggle()}}},'v-list-item-icon',attrs,false),on),[_c('v-icon',{attrs:{"color":"color1"}},[_vm._v(" mdi-calendar-month ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.edadActual)+" años")])])]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" 06/03/1988 ")]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" Fecha de nacimiento ")])],1)],1)],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center justify-sm-start altura",attrs:{"cols":"12","sm":"2"}},[_c('v-list-item-icon',[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"icono correo","icon":"","elevation":"4","href":"mailto: alcafefront@gmail.com","target":"_blank"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"color1"}},[_vm._v(" mdi-at ")])],1)]}}],null,true)},[_c('span',[_vm._v("Escribir correo")])])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-list-item-content',{staticClass:"mb-2"},[_c('v-list-item-title',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" alcafefront@gmail.com ")]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" Correo electrónico ")])],1)],1)],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center  justify-sm-start altura",attrs:{"cols":"12","sm":"2"}},[_c('v-list-item-icon',[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"icono teléfono","icon":"","elevation":"4","href":"tel:635507429","target":"_blank"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"color1"}},[_vm._v(" mdi-phone ")])],1)]}}],null,true)},[_c('span',[_vm._v("Llamar por teléfono")])])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" 635507429 ")]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" Móvil ")])],1)],1)],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center  justify-sm-start altura",attrs:{"cols":"12","sm":"2"}},[_c('v-list-item-icon',[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"icono Github","icon":"","elevation":"4","href":"https://github.com/AlbertoCF88","target":"_blank","rel":"noreferrer"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"color1"}},[_vm._v(" mdi-github ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ir a Github")])])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" AlbertoCF88 ")]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" Cuenta en github.com ")])],1)],1)],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center  justify-sm-start altura",attrs:{"cols":"12","sm":"2"}},[_c('v-list-item-icon',[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"icono google maps","icon":"","elevation":"4","href":"https://www.google.es/maps/place/Paterna,+Valencia/@39.5029344,-0.451063,15z/data=!3m1!4b1!4m5!3m4!1s0xd60454892e32d6d:0xb45b9f3dbffa9227!8m2!3d39.5037093!4d-0.4431618?hl=es","target":"_blank","rel":"noreferrer"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"color1"}},[_vm._v(" mdi-map-marker ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ir a Google Maps")])])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" Paterna, Valencia, España ")]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center justify-sm-start"},[_vm._v(" Dirección ")])],1)],1)],1)],1)],1),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cv marginTop",attrs:{"aria-label":"icono descargar CV","text":"","block":"","color":"color4","href":"/files/cv.pdf","target":"_blank"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Curriculum pdf ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Cv.pdf")])])]}}])}),_c('div',{staticClass:"pie"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }